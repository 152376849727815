<template>

  <div :class="'design--'+prelayer.design">
    <div class="prelayer" :style="'font-family: '+prelayerStyle.schriftart+';background:'+prelayerStyle.farbeHintergrund">
      <div class="prelayerTitle" :style="'color:'+prelayerStyle.farbeTitel+';'">{{prelayer.titel}}</div>
      <div class="prelayerText" :style="'color:'+prelayerStyle.farbeText+';'">{{prelayer.text}}</div>
      <div class="prelayerDatenschutz" v-if="prelayer.datenschutzAnzeigen">
        <a :href="prelayer.datenschutzUrl" target="_blank">{{prelayer.datenschutzUrl}}</a>
      </div>
      <div class="prelayerButtons" >
        <v-btn class="prelayerButtenAccept" :style="'color:'+prelayerStyle.farbeButtonAkzeptieren+';background:'+prelayerStyle.farbeHintergrundButtonAkzeptieren+';'">{{prelayer.textButtonAkzeptieren}}</v-btn>
        <v-btn class="prelayerButtenDeny" :style="'color:'+prelayerStyle.farbeButtonAblehnen+';background:'+prelayerStyle.farbeHintergrundButtonAblehnen+';'">{{prelayer.textButtonAblehnen}}</v-btn>
      </div>
    </div>

    <div class="prelayerPushIcon" v-if="mainData.angabePrelayerPushIconBenutzen">
      <v-icon size="58" v-if="prelayerPushIcon.iconTyp == 'klassisch'" :style="'color:'+prelayerPushIcon.farbeIcon+';background:'+prelayerPushIcon.farbeIconHintergrund+';'">
        {{ icons.mdiBell }}
      </v-icon>
      <v-icon size="58" v-if="prelayerPushIcon.iconTyp == 'modern'" :style="'color:'+prelayerPushIcon.farbeIcon+';background:'+prelayerPushIcon.farbeIconHintergrund+';'">
        {{ icons.mdiBellOutline }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import {
  mdiBell, mdiBellOutline
} from "@mdi/js";

export default {
  components:{

  },
  props:{
    mainData:{
      type:Object,
      default:() => {},
      required:false
    },
    prelayer:{
      type:Object,
      default:() => {},
      required:false
    },
    prelayerStyle:{
      type:Object,
      default:() => {},
      required:false
    },
    prelayerPushIcon:{
      type:Object,
      default:() => {},
      required:false
    },
  },
  setup(props,{emit}) {
    return {
      icons: {
        mdiBell,
        mdiBellOutline
      },
    }
  },
}
</script>

<style scoped lang="scss">

  .design--default{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display:flex;
    justify-content: center;
    min-height:500px;

    .prelayer{
      display:flex;
      flex-direction: column;
      padding:10px 20px 20px 20px;
      border:2px solid #000;
      width: 70%;
      height: 100%;
    }

    .prelayerTitle{
      font-size: 20px;
      margin-bottom:10px;
    }

    .prelayerText{
      text-align: right;
      line-height: 16px;
    }

    .prelayerDatenschutz{
      margin:10px 0px;
      text-align: right;
    }

    .prelayerButtons{
      display: flex;
      justify-content: center;
      margin-top:20px;
    }

    .prelayerButtenAccept,
    .prelayerButtenDeny{
      margin:0px 10px;
    }

    .prelayerPushIcon{
      position: absolute;
      bottom:0px;
      left:0;
    }
  }



  .design--modern{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display:flex;
    justify-content: center;
    min-height:500px;

    .prelayer{
      display:flex;
      flex-direction: column;
      padding:10px 20px 20px 20px;
      border:1px solid #000;
      width: 70%;
      height: 100%;
    }

    .prelayerTitle{
      font-size: 14px;
      margin-bottom:10px;
    }

    .prelayerText{
      text-align: right;
      line-height: 16px;
    }

    .prelayerDatenschutz{
      margin:10px 0px;
      text-align: right;
    }

    .prelayerDatenschutz a{
      color:blue;
    }

    .prelayerButtons{
      display: flex;
      justify-content: center;
      margin-top:20px;

    }

    .prelayerButtenAccept,
    .prelayerButtenDeny{
      margin:0px 10px;
      padding:2px;
      min-width: 70px;
    }
    .prelayerPushIcon{
      position: absolute;
      bottom:0px;
      left:0;
    }
  }

</style>
